.about-us-container {
    h1 {
        text-align: center;
        margin-top: 20px;
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;

        .description {
            flex: 1;
            padding-right: 20px;

            p {
                margin-bottom: 10px;
                margin-left: 20px;
            }

            a {
                text-decoration: underline;
                color: blue;
                cursor: pointer;
            }
        }

        .image-container {
            flex: 1;

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}
