.checkout-page {
    max-width: 50%;
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
    }
  
    .form-group {
      margin-bottom: 20px;
  
      label {
        display: block;
        margin-bottom: 5px;
      }
  
      input[type="text"],
      input[type="email"],
      textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
        font-size: 16px;
      }
  
      textarea {
        resize: vertical;
      }
    }
  
    .payment-method {
      label {
        display: block;
        margin-bottom: 10px;
  
        input[type="radio"] {
          margin-right: 5px;
        }
      }
    }
  
    button[type="submit"] {
      display: block;
      width: 100%;
      padding: 10px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  