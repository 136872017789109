.payment-page {
    max-width: 800px;
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .payment-methods {
      display: flex;
      justify-content: center;
    }
  
    .payment-item {
      width: 100%;
      max-width: 500px;
      margin-bottom: 40px;
      padding: 20px;
      background-color: #f9f9f9;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
      h3 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 10px;
      }
  
      img {
        display: block;
        width: 100%;
        max-width: 200px;
        margin: 0 auto 20px;
        border-radius: 5px;
      }
  
      p {
        font-size: 16px;
        margin-bottom: 10px;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
  
      select {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
      }
  
      .payment-options {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
      ol {
        margin-left: 20px;
        font-size: 16px;
        margin-bottom: 20px;
  
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .align-center {
    align-items: center;
  }
  
  .my-1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  