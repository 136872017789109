@import "../../App.scss";

.header{
    background: rgb(231, 23, 26);
    background: linear-gradient(180deg, rgb(231, 23, 26) 0%, rgba(231,23,26,1) 100%);

    .header-cnt-top{
        border-bottom: 1px solid lighten($clr-orange, 10%);
    }

    @media screen and (max-width: 992px){
        .header-cnt-top{
            flex-direction: column;
            .header-cnt-top-l{
                margin-bottom: 4px;
            }
        }
    }
    
}